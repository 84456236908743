<template>
  <el-form
    ref="form"
    :model="model"
    :rules="rules"
    :inline="inline"
    :label-position="labelPosition"
    :label-width="labelWidth"
    :label-suffix="labelSuffix"
    :size="size"
    :disabled="disabled"
  >
    <slot></slot>
  </el-form>
</template>

<script>
export default {
  name: 'ExtElForm',
  props: {
    model: {
      require: true,
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    inline: {
      type: Boolean,
      default: false,
    },
    labelPosition: {
      type: String,
      default: 'right',
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    labelSuffix: String,
    size: {
      type: String,
      default: 'medium',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    validate(callback) {
      const formDOM = this.$refs.form;
      return formDOM.validate(callback);
    },
    validateField(props, callback) {
      const formDOM = this.$refs.form;
      formDOM.validateField(props, callback);
    },
    resetFields() {
      const formDOM = this.$refs.form;
      formDOM.resetFields();
    },
  },
};
</script>

<style></style>
