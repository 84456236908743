const systemRoute = {
  constantRoutes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/system/login/index.vue'),
    },
  ],
  asyncRoutes: [],
};

export default systemRoute;
