/* Layout */
import Layout from '@/views/back/layout/index.vue';

const backRoute = {
  constantRoutes: [],
  /**
   * 异步路由
   * 需要根据用户角色动态加载的路由
   */
  asyncRoutes: [
    {
      path: '/admin',
      component: Layout,
      meta: {
        title: '后台首页',
      },
      children: [
        {
          path: '',
          name: 'BackHome',
          component: () => import(/* webpackChunkName: "back-home" */ '@/views/back/home/index.vue'),
          hidden: true,
        },
      ],
    },
    {
      path: '/admin/permission',
      component: Layout,
      meta: {
        title: '权限管理',
        roles: ['admin', 'editor'],
      },
      children: [
        {
          path: '',
          name: 'BackPermission',
          component: () => import(/* webpackChunkName: "back-permission" */ '@/views/back/permission/index.vue'),
          hidden: true,
        },
        {
          path: 'role',
          name: 'BackPermissionRole',
          component: () => import(/* webpackChunkName: "back-permission-role" */ '@/views/back/permission/role.vue'),
          meta: {
            title: '角色管理',
            roles: ['admin'],
          },
        },
      ],
    },
  ],
};

export default backRoute;
