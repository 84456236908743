import Vue from 'vue';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import '@/styles/plugins/nprogress.scss'; // local progress bar style

NProgress.configure({
  parent: 'body',
  minimum: 0.1,
  easing: 'ease',
  speed: 500,
  showSpinner: false, // 关闭加载微调器
  trickleSpeed: 1000, // 调整涓流/增量的频率，以毫秒为单位。
  // trickle: false, // 关闭自动递增行
});

Vue.prototype.$progress = NProgress;
