import Vue from 'vue';
import '@/styles/plugins/element.variables.scss';
import {
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Carousel,
  CarouselItem,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Divider,
  Empty,
  Form,
  FormItem,
  Pagination,
  Input,
  Image,
  Message,
  MessageBox,
  Option,
  Select,
  Tabs,
  TabPane,
  Upload,
  Row,
  Col,
} from 'element-ui';

Vue.use(Button);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Image);
Vue.use(Option);
Vue.use(Select);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Upload);
Vue.use(Row);
Vue.use(Col);

Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;

const componentsContext = require.context('@/components/Extend/Element', true, /\.js$/);
componentsContext.keys().forEach((item) => {
  const component = componentsContext(item);
  Vue.use(component.default || component);
});
