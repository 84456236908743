/* Layout */
import Layout from '@/views/front/layout/index.vue';
import HomeLayout from '@/views/front/layout/home.vue';

const frontRoute = {
  constantRoutes: [
    {
      path: '/',
      component: HomeLayout,
      children: [
        {
          path: '',
          name: 'Home',
          // route level code-splitting
          // this generates a separate chunk (front-layout.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "home" */ '@/views/front/home/index.vue'),
        },
      ],
    },
    {
      path: '/article/:id',
      name: 'Article',
      component: Layout,
      children: [
        {
          path: '',
          name: 'ArticleIndex',
          component: () => import(/* webpackChunkName: "article" */ '@/views/front/article/index.vue'),
        },
      ],
    },
    {
      path: '/product/:id',
      name: 'Product',
      component: Layout,
      children: [
        {
          path: '',
          name: 'ProductIndex',
          component: () => import(/* webpackChunkName: "product" */ '@/views/front/article/index.vue'),
        },
      ],
    },
    {
      path: '/news/:id',
      name: 'News',
      component: Layout,
      children: [
        {
          path: '',
          name: 'NewsIndex',
          component: () => import(/* webpackChunkName: "news" */ '@/views/front/article/index.vue'),
        },
      ],
    },
    {
      path: '/details/:id',
      name: 'Details',
      component: Layout,
      children: [
        {
          path: '',
          name: 'ArticleDetails',
          component: () => import(/* webpackChunkName: "details" */ '@/views/front/article/details.vue'),
        },
      ],
    },
    {
      path: '/search/:keyword',
      name: 'Search',
      component: Layout,
      children: [
        {
          path: '',
          name: 'SearchIndex',
          component: () => import(/* webpackChunkName: "search" */ '@/views/front/search/index.vue'),
        },
      ],
    },
  ],
  asyncRoutes: [
    {
      path: '/user',
      name: 'User',
      component: Layout,
      children: [
        {
          path: '',
          name: 'UserCenter',
          component: () => import(/* webpackChunkName: "userCenter" */ '@/views/front/user/center/index.vue'),
        },
        {
          path: 'comment',
          name: 'UserComment',
          component: () => import(/* webpackChunkName: "userComment" */ '@/views/front/user/comment/index.vue'),
        },
      ],
    },
  ],
};

export default frontRoute;
