import navigationService from '@/services/navigation';

export default {
  state: {
    navigation: [],
  },
  getters: {
    // 搜索栏目
    findNavigation: (state) => (fun) => {
      let result = state.navigation.find(fun);
      if (!result) {
        state.navigation.forEach((item) => {
          const childrenResult = item.children.find(fun);
          if (childrenResult) result = childrenResult;
        });
      }
      return result;
    },
  },
  mutations: {
    SET_NAVIGATION: (state, navigation) => {
      navigation.forEach((item) => {
        item.children.forEach((children) => {
          // 记录二级导航
          // eslint-disable-next-line no-param-reassign
          children.parent = item;
        });
      });
      state.navigation = navigation;
    },
  },
  actions: {
    getNavigation({ commit }) {
      navigationService.list().then(({ data }) => {
        commit('SET_NAVIGATION', data);
      });
    },
  },
};
