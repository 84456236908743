<template>
  <footer>
    <div class="layout-block">
      <div class="block-1">
        <div class="grid grid-1">
          <div class="container">
            <div class="logo">
              <div class="cover">
                <img src="~@/assets/images/logo.png"
                  @error="handleError"
                  alt="logo">
              </div>
              <div class="title">
                <h3>
                  <span class="hightlight">APQS</span>
                  <span>GBA-JM</span>
                </h3>
              </div>
            </div>
            <p>大湾区（江门）农产品质量安全服务平台</p>
          </div>
        </div>
        <div class="grid grid-2">
          <div class="container">
            <h4>关于我们</h4>
            <div class="content">
              <p>备案编号：<a href="https://beian.miit.gov.cn/">粤ICP备2023126079号</a></p>
            </div>
          </div>
        </div>
        <div class="grid grid-3">
          <div class="container">
            <h4>联系我们</h4>
          </div>
        </div>
      </div>
      <div class="block-2">
        <div class="copyright">
          <span>© 2022 copyright All Rights Reserved</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import loadFailImg from '@/assets/images/load-fail.png';

export default {
  name: 'FrontFooter',
  components: {},
  data() {
    return {
      loadFail: loadFailImg,
    };
  },
  methods: {
    // 加载失败图片
    handleError(e) {
      /* eslint-disable */
      e.target.src = this.loadFail;
    },
  },
};
</script>

<style lang='scss' scoped>
footer {
  .layout-block {
    padding: 0 20px;
    .block-1 {
      display: flex;
      padding-bottom: 30px;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }
    .grid {
      flex: 1;
      padding-right: 15px;
      @include media-breakpoint-down(md) {
        flex: auto;
        padding-bottom: 15px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .container {
      max-width: 300px;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    .cover {
      max-width: 50px;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    .hightlight {
      margin-right: 5px;
      color: var(--theme-color);
    }
  }
  .content {
    margin-top: 40px;
  }
  p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.7;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      margin-right: 10px;
    }
  }
  .copyright {
    border-top: 1px solid #eee;
    padding: 30px 0;
    text-align: center;

    span {
      font-size: 16px;
    }
  }
}
</style>
