<template>
  <el-dialog title="提示"
    :visible.sync="myVisible"
    width="30%">
    <slot></slot>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'ExtElDialog',
  props: {
    visible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      myVisible: this.visible,
    };
  },
  watch: {
    visible(val) {
      this.myVisible = val;
    },
    myVisible(val) {
      this.$emit('update:visible', val);
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped></style>
