const errorRoute = {
  constantRoutes: [],
  asyncRoutes: [
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/error/404/index.vue'),
    },
  ],
};

export default errorRoute;
