<template>
  <div class="cq-menu cq-menu--default"
    :class="[`cq-menu--${mode}`]">
    <ul class="cq-menu--list">
      <li v-for="(lv1, lv1i) in data"
        :key="lv1i"
        class="cq-menu--item">
        <div class="cq-menu--container"
          :class="{'cq-accordion':mode === 'vertical'&& lv1.children && lv1.children.length > 0}">
          <div v-if="mode === 'vertical' && lv1.children && lv1.children.length > 0"
            class="cq-menu--label cq-accordion--lable is-alone">
            <span class="cq-menu--title">{{lv1.name}}</span>
          </div>
          <router-link v-else-if="lv1.name !== '个人中心'"
            class="cq-menu--label"
            :to="routerLink(lv1, lv1i)">
            <span class="cq-menu--title">{{lv1.name}}</span>
          </router-link>
          <div v-else
            class="cq-menu--label"
            @click="onLogin"
            @keydown="onLogin">
            <span class="cq-menu--title">{{lv1.name}}</span>
          </div>
          <!-- 二级导航 -->
          <div v-if="lv1.children && lv1.children.length > 0"
            class="cq-menu--children is-level-2"
            :class="{'cq-accordion--content': mode === 'vertical'}">
            <ul class="cq-menu--list">
              <li v-for="(lv2, lv2i) in lv1.children"
                :key="lv2i"
                class="cq-menu--item">
                <div class="cq-menu--container">
                  <router-link class="cq-menu--label"
                    :to="routerLink(lv2, lv2i)">
                    <span class="cq-menu--title">{{lv2.name}}</span>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CQueryMenu',
  components: {},
  props: {
    // 模式
    mode: {
      type: String,
      default: 'horizontal',
    },
    data: {
      type: Array,
      default: () => ([]),
    },
    routerLink: {
      type: Function,
      default: () => '#',
    },
    onLogin: {
      type: Function,
      default: () => '',
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      this.$cq.Accordion.setupDefault();
    });
  },
};
</script>

<style lang='scss' scoped>
.is-level-2 {
  --color-text: #333;
}
</style>
