<template>
  <!-- 页面整体容器(一般用作页面背景) -->
  <div class="page-container">
    <!-- 布局容器(限制页面最大宽度) -->
    <div class="layout-container">
      <!-- 每块布局块 -->
      <div class="layout-grid-1">
        <span>后台导航栏</span>
        <ul>
          <template v-for="(item, index) in backRoute">
            <li v-if="!item.hidden"
              :key="index">
              <span>{{ item.meta.title }}</span>
              <ul v-if="item.children">
                <template v-for="(child, cindex) in item.children">
                  <li v-if="!child.hidden"
                    :key="cindex">
                    <span>{{ child.meta.title }}</span>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
      </div>
      <div class="layout-grid-2">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FrontLayout',
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState('permission', ['backRoute']),
  },
  methods: {},
  created() {
    console.log(this.backRoute);
  },
};
</script>

<style lang='scss' scoped></style>
