<template>
  <div class="loading-mask"
    v-show="visible">
    <div class="loading-spinner">
      <svg viewBox="25 25 50 50"
        class="circular">
        <circle cx="50"
          cy="50"
          r="20"
          fill="none"
          class="path"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyLoading',
  data() {
    return {
      visible: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.loading-parent--relative {
  position: relative !important;
}
.el-loading-parent--hidden {
  overflow: hidden !important;
}

.loading-mask {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
  background-color: rgba(255, 255, 255, 0.9);

  &.is-fullscreen {
    position: fixed;
  }

  .loading-spinner {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;

    .circular {
      height: 42px;
      width: 42px;
      animation: loading-rotate 2s linear infinite;

      .path {
        animation: loading-dash 1.5s ease-in-out infinite;
        stroke-dasharray: 90, 150;
        stroke-dashoffset: 0;
        stroke-width: 2;
        stroke: #409eff;
        stroke-linecap: round;
      }
    }
  }
}
</style>
